<template>
  <div class="groups">
    <div class="groups__select">
      <div class="groups__select-item">
        <p>{{ $t('year') }}</p>
        <ui-select v-model="year" :options="getYears" @input="getTypes"/>
      </div>
      <div class="groups__select-item">
        <p>{{ $t('type-2') }}</p>
        <ui-select v-model="type" :options="getRegionTypes" @input="getSubtypes"/>
      </div>
      <div class="groups__select-item">
        <p>{{ $t('subtype') }}</p>
        <ui-select v-model="subtype" :options="getRegionSubtypes" @input="getRegionGroups"/>
      </div>
    </div>
    <UiLoader v-model="loader" />
    <UiNotification v-model="showNotification" :message="message" />
    <Table
        v-if="(subtype.name && !loader && getGroups.length) || isEmpty"
        :title="$t('groups-of-indicators')"
        :descriptionList="[{title: $t('group'), width: 420},{title: $t('weight'), width: 256, paddingLeft: 0, textAlign: 'center'}]"
        :actionText="$t('add-line')"
        :data="getGroups"
        :sort="true"
        hideEditIcon
        is-ratio
        @addRowField="addRegionGroup"
        @changeRowField="editRegionGroup"
        @deleteRowField="deleteRegionGroup"
        ref="table"
    />
    <div class="groups__actions">
      <ui-button v-if="subtype.name && !loader && !getGroups.length && !isEmpty" color="error" @click="addNewTable">
        <img src="@/assets/svg/icon-plus-white.svg" alt="">
        {{ $t('new-table') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiNotification from "@/components/ui/UiNotification.vue";
import Table from "@/components/ui/UiTable.vue";
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "Groups",
  components: {UiButton, Table, UiNotification, UiLoader, UiSelect},

  data () {
    return {
      year: {},
      type: {},
      subtype: {},
      loader: false,
      showNotification: false,
      message: '',
      isEmpty: false
    }
  },

  computed: {
    ...mapGetters(['getRegionTypes', 'getRegionSubtypes', 'getGroups', 'getYears']),
  },

  methods: {
    ...mapActions([
      'years',
      'regionTypes',
      'regionSubtypes',
      'groups',
      'addGroup',
      'editGroup',
      'deleteGroup'
    ]),
    ...mapMutations(['setRegionTypes', 'setRegionSubtypes', 'setYears']),

    addNewTable() {
      this.isEmpty = true
      setTimeout(() => {
        this.$refs.table.addItem();
      }, 100)
    },

    loadPage(message) {
      this.groups({subtypeId: this.subtype.id}).then(() => {
        setTimeout(() => {
          this.showNotification = true
          this.loader = false;
          this.message = message
        }, 500)
      })
    },

    getTypes () {
      this.type = {};
      this.subtype = {};
      this.regionTypes(this.year.value)
      this.setRegionSubtypes([])
    },

    getSubtypes () {
      this.subtype = {};
      this.regionSubtypes({typeId: this.type.id})
    },

    getRegionGroups () {
      this.loader = true;
      this.groups({subtypeId: this.subtype.id}).then(() => {
        this.loader = false;
      })
    },

    addRegionGroup (data) {
      this.isEmpty = false;
      const payload = {
        name: data.name,
        year: this.year.value,
        ratio: +data.ratio,
        ord: +data.ord,
        subtypeId: this.subtype.id
      }
      this.loader = true
      this.addGroup(payload).then(() => {
        this.loadPage(this.$t('group-successfully-added'))
      })
    },

    editRegionGroup(data) {
      const payload = {
        id: data.id,
        name: data.name,
        ratio: +data.ratio,
        ord: +data.ord
      }
      this.loader = true
      this.editGroup(payload).then(() => {
        this.loadPage(this.$t('group-successfully-updated'))
      })
    },

    deleteRegionGroup(data) {
      this.loader = true
      this.deleteGroup(data).then(() => {
        this.loadPage(this.$t('group-successfully-deleted'))
      })
    }
  },

  mounted() {
    this.setRegionTypes([])
    this.setRegionSubtypes([])
    this.setYears([])
    this.years().then((res) => {
      let data = res.map((elem) => {
        elem.name = elem.id
        elem.value = elem.id
        return elem
      })
      this.loader = false;
      this.setYears(data)
    })
  }
}
</script>

<style lang="scss" scoped>
.groups {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 500px;

  @media (max-width: 1000px) {
    overflow-x: auto;
  }

  &__select {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 20px;
    max-width: 433px;
    width: 100%;

    ::v-deep .ui-select {
      z-index: auto;
      max-width: 305px;
    }
    ::v-deep .ui-select__label {
      display: none;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      p {
        font-size: 16px;
        line-height: 19px;
        color: #1B1A1F;
        white-space: nowrap;
      }
    }
  }

  &__actions {
    button {
      max-width: 366px;
    }
  }
}
</style>